import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormGroup, Button } from "react-bootstrap";

const PatientKulawruttaForm = (props) => {

  const { initialValues, onSubmit } = props;

const patientKulawruttaInfo = props.initialValues[5].patientKulawrutta[0];


  // Define the form's initial values
  const formInitialValues = {
    PATIENT_KULAWRUTTA: patientKulawruttaInfo.PATIENT_KULAWRUTTA || "",
  };

  // Define the validation schema
  const validationSchema = Yup.object().shape({
    PATIENT_KULAWRUTTA: Yup.string(),
  });

  return (
    <div className="form-wrapper">
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values); // Pass form values to the parent component's onSubmit function
          setSubmitting(false);
        }}
      >
        <Form>
          <FormGroup>
            <label htmlFor="PATIENT_KULAWRUTTA">Kulawrutta</label>
            <Field name="PATIENT_KULAWRUTTA" as="textarea" className="form-control" />
            <ErrorMessage name="PATIENT_KULAWRUTTA" component="div" className="invalid-feedback" />
          </FormGroup>

          {/* You can add more FormGroup components for additional fields as needed */}

          <Button type="submit" variant="primary">
            Submit
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default PatientKulawruttaForm;
