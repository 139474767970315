// EditStudent Component for update student data 

// Import Modules 
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import PatientInfoForm from "../PatientInfoForm";
import CONFIG from '../../config';
import AuthUtil from "../Mandali/AuthUtil";


// EditStudent Component 
const AddPatientInfo = () => {

    // let { patientId, caseId, visitId } = useParams();

    //  console.log ('in AddPatientInfo :', visitId); 
    const [selectedVisit, setSelectedVisit] = useState(null);
    const [submissionState, setSubmissionState] = useState(null);
    const baseURL = CONFIG.HTTP_PROTCOL + CONFIG.DOMAIN_NAME;

    let navigate = useNavigate();
    //const [formValues, setFormValues] = useState({ Student_Name: '', Student_Email: '', Student_Roll_Number: '' })

    const {verifyTokenAndRedirect} = AuthUtil();
    
    useEffect(()=>{
  
      verifyTokenAndRedirect();
  
    }, []);


    useEffect(() => {


        console.log('inside use effect for PATIENT_ID : ', selectedVisit)

        if (submissionState?.submitted && selectedVisit) {

//            // add patient to database
//            const newPatientInfo = addPatientDetails(selectedVisit)

            // get patient id and update in the selected visit object
//            console.log('New patient info from the database: ', newPatientInfo);

            const prevSelectedVisit = selectedVisit;

            console.log (selectedVisit[0].patientInfo[0].PATIENT_ID)

            if (selectedVisit[0].patientInfo[0].PATIENT_ID != 0) {

                console.log(selectedVisit); // This will log the updated state
                navigate("/visits/symptoms", { state: { prevSelectedVisit } });
            }
        }

    }, [submissionState, selectedVisit]);



    //onSubmit handler 
    const onSubmitHandler = async (patientData) => {

        //alert("On Submit");

        setSubmissionState({ submitted: false });

                    // add patient to database
                    const newPatientResponse = await addPatientDetails(patientData);

                    console.log(newPatientResponse);

                    const newPatientInfo = newPatientResponse[1].newPatientInfo[0];


//        setSelectedVisit((newPatientVisit) => {

            const newPatientVisitStructure = [
                {
                    "patientInfo": [
                        {
                            "PATIENT_ID": 0,
                            "PATIENT_NAME": "",
                            "PATIENT_AGE": 0,
                            "PATIENT_ADDRESS": "",
                            "PATIENT_PHONE": "",
                            "PATIENT_OCCUPATION": "",
                            "LAST_UPDATE_TIME": "",
                            "LAST_UPDATE_USER": "Doctor",
                            "EMAILID": "",
                            "DELETE_PATIENT": 0,
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1,
                            "PATIENT_GENDER": "",
                            "PATIENT_DOB": "",
                            "PATIENT_BLOODGRP": "",
                            "PATIENT_MOBILE_NUM": ""
                        }
                    ]
                },
                {
                    "patientSymptoms": [
                        {
                            "CASE_ID": 1,
                            "PATIENT_ID": newPatientInfo.PATIENT_ID,
                            "VISIT_ID": 1,
                            "PATIENT_SYMPTOMS": "",
                            "VISIT_DATE": "",
                            "LAST_UPDATE_DATE": "",
                            "LAST_UPDATE_USER": "Doctor",
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1,
                            "DELETE_CASE": "N"
                        }
                    ]
                },
                {
                    "patientHistory": [
                        {
                            "CASE_ID": 1,
                            "PATIENT_ID": newPatientInfo.PATIENT_ID,
                            "PATIENT_HISTORY": "",
                            "LAST_UPDATE_DATE": "",
                            "LAST_UPDATE_USER": "Doctor",
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1
                        }
                    ]
                },
                {
                    "patientClinicalInvestigation": [
                        {
                            "CASE_ID": 1,
                            "PATIENT_ID": newPatientInfo.PATIENT_ID,
                            "VISIT_ID": 1,
                            "PATIENT_NADI": "",
                            "PATIENT_GATI": "",
                            "PATIENT_JIVA": "",
                            "PATIENT_MAL": "",
                            "PATIENT_MUTR": "",
                            "PATIENT_NETRA": "",
                            "PATIENT_HRADAY": "",
                            "PATIENT_PHUPPUS": "",
                            "LAST_UPDATE_DATE": "",
                            "LAST_UPDATE_USER": "Doctor",
                            "PATIENT_NIDRA": "",
                            "PATIENT_AGNI": "",
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1
                        }
                    ]
                },
                {
                    "patientAnyavyadhi": [
                        {
                            "CASE_ID": 1,
                            "PATIENT_ID": newPatientInfo.PATIENT_ID,
                            "VISIT_ID": 1,
                            "PATIENT_ANYAVYADHI": "",
                            "LAST_UPDATE_DATE": "",
                            "LAST_UPDATE_USER": "Doctor",
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1
                        }
                    ]
                },
                {
                    "patientKulawrutta": [
                        {
                            "CASE_ID": 1,
                            "PATIENT_ID": newPatientInfo.PATIENT_ID,
                            "PATIENT_KULAWRUTTA": "",
                            "LAST_UPDATE_DATE": "",
                            "LAST_UPDATE_USER": "Doctor",
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1
                        }
                    ]
                },
                {
                    "patientTapasanya": [
                        {
                            "CASE_ID": 1,
                            "PATIENT_ID": newPatientInfo.PATIENT_ID,
                            "VISIT_ID": 1,
                            "VISIT_DATE": "",
                            "PATIENT_TAPASANYA": "",
                            "LAST_UPDATE_DATE": "",
                            "LAST_UPDATE_USER": "Doctor",
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1,
                            "PATIENT_WEIGHT": "",
                            "PATIENT_BP": "",
                            "PATIENT_PULSERATE": ""
                        }
                    ]
                },
                {
                    "patientArtawawrutta": [
                        {
                            "CASE_ID": 1,
                            "PATIENT_ID": newPatientInfo.PATIENT_ID,
                            "VISIT_ID": 1,
                            "VISIT_DATE": "",
                            "DIAGNOSIS_DETAILS": "",
                            "LAST_UPDATED_TIME": "",
                            "LAST_USER": "Doctor",
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1
                        }
                    ]
                },
                {
                    "patientDiagnosis": [
                        {
                            "CASE_ID": 1,
                            "PATIENT_ID": newPatientInfo.PATIENT_ID,
                            "VISIT_ID": 1,
                            "NIDAN_DETAILS": "",
                            "LAST_UPDATE_DATE": "",
                            "LAST_UPDATE_USER": "Doctor",
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1
                        }
                    ]
                },
                {
                    "patientTreatment": [
                        {
                            "CASE_ID": 1,
                            "PATIENT_ID": newPatientInfo.PATIENT_ID,
                            "VISIT_ID": 1,
                            "TREATMENT_DETAILS": "",
                            "LAST_UPDATE_DATE": "",
                            "LAST_UPDATE_USER": "Doctor",
                            "DIVAS": 30,
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1
                        }
                    ]
                },
                {
                    "patientNextVisit": [
                        {
                            "CASE_ID": 1,
                            "PATIENT_ID": newPatientInfo.PATIENT_ID,
                            "VISIT_ID": 1,
                            "NEXT_VISIT_DATE": "",
                            "NEXT_VISIT_TIME": "",
                            "NEXT_VISIT_DAY": "",
                            "LAST_UPDATE_DATE": "",
                            "LAST_UPDATE_USER": "Doctor"
                        }
                    ]
                },
                {
                    "flags": [
                        {
                            "NEW_PATIENT_FLAG": "Y",
                            "NEW_CASE_FLAG": "Y"
                        }
                    ]
                }
            ];

/*            
            const updatedPatientInfo = {
                ...newPatientVisitStructure[0].patientInfo[0],
                ...newPatientInfo,
            };
*/
            const newPatientVisit = [{ patientInfo: [newPatientInfo] }, ...newPatientVisitStructure.slice(1)];

            setSelectedVisit(newPatientVisit);

            console.log('New Patient Info : ', newPatientVisit);

//            return newPatientVisit;

 //       });



//        console.log("patient data after submit:", patientData); // Log the data to be updated

//        console.log("selected Visit after submit:", selectedVisit); // Log the data to be updated

        setSubmissionState({ submitted: true });


    };


    const addPatientDetails = async (patientData) => {
        try {

            console.log('patient data before addition to db', patientData);
           // const patientInfo = selectedVisit[0].patientInfo[0];

            const response = await axios.post(`${baseURL}/patients/addpatient`, patientData);

            

            console.log('Response:', response.data);

            return response.data;
            // Handle success (e.g., show a success message)
        } catch (error) {
            console.error('Error updating patient details:', error.response ? error.response.data : error.message);
            // Handle error (e.g., show an error message)
        }
    };





    // Return student form 
    return (

        <div>
            {console.log('inside rendering: ', selectedVisit)}
            {(
                <PatientInfoForm
                    initialValues={selectedVisit}
                    onSubmit={onSubmitHandler}
                    enableReinitialize={true}
                >
                    Update Patient
                </PatientInfoForm>
            )}
        </div>
    );

};

// Export EditStudent Component 
export default AddPatientInfo;
