import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Import Bootstrap
import { Nav, Navbar, Container, Row, Col }
  from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";

// Import Custom CSS
import "./App.css";

// Import from react-router-dom
import {
  BrowserRouter as Router, Routes,
  Route, Link
} from "react-router-dom";

import axios from 'axios';
import PatientVisits from './Components/PatientVisits'; // Import the PatientVisits component
import EditPatientInfo from './Components/EditPatientInfo';
import EditPatientSymptoms from './Components/EditPatientSymptoms';
import EditPatientHistory from './Components/EditPatientHistory';
import EditPatientCI from './Components/EditPatientCI';
import EditPatientAnyavyadhi from './Components/EditPatientAnyavyadhi';
import EditPatientKulawrutta from './Components/EditPatientKulawrutta';
import EditPatientTapasyana from './Components/EditPatientTapasanya';
import EditPatientArtawawrutta from './Components/EditPatientArtawawrutta';
import EditPatientDiagnosis from './Components/EditPatientDiagnosis';
import EditPatientTreatment from './Components/EditPatientTreatment';
import EditPatientNextVisit from './Components/EditPatientNextVisit';
import AddPatientInfo from './Components/Add/AddPatientInfo';
import AddNewCase from './Components/Add/AddNewCase';
import CONFIG from './config';
import EditMandaliInfo from './Components/Edit/EditMandaliInfo';
import CheckToken from './Components/Mandali/CheckToken';
import CheckForm from './Components/Mandali/CheckForm';
import SelectPatientAndCases from './Components/SelectPatientAndCases';


const App = () => {


  return (

    <Router>
      <div className="App">
        <header className="App-header">
          <Navbar bg="dark" variant="dark" style={{ height: '60px' }}>
            <Container>
              <Navbar.Brand>
                <Link to={"/patientlist"}
                  className="nav-link">
                  Patient
                </Link>
              </Navbar.Brand>

              <Nav className="justify-content-end">
                <Nav>
                  <Link to={"/addpatient"}
                    className="nav-link">
                    Add Patient
                  </Link>
                </Nav>

                <Nav>
                  <Link to={"/patientlist"}
                    className="nav-link">
                    Patient List
                  </Link>
                </Nav>

                <Nav>
                  <Link to={"/getmandaliinfo"}
                    className="nav-link">
                    Mandali Info
                  </Link>
                </Nav>


              </Nav>
            </Container>
          </Navbar>
        </header>

        <Routes>
        <Route path="/" element={<CheckToken />} />

          <Route path='/patientlist' element={ <SelectPatientAndCases />} />
          
          <Route path="/check" element={<CheckForm />} />
          <Route path="/addpatient" element={<AddPatientInfo />} / >
          <Route path="/getmandaliinfo" element={<EditMandaliInfo/>} / >

          <Route path="/visits/:patientId/:caseId" element={<PatientVisits />} / >
          
          { /* PATIENT INFO - 0 */}
          <Route path="/visits/:patientId/:caseId/:visitId" element={<EditPatientInfo />} />
          
          { /* SYMPTOMPS - 1 */}
          <Route path="/visits/symptoms" element={<EditPatientSymptoms />} />

          { /* HISTORY - 2 */}
          <Route path="/visits/history" element={<EditPatientHistory />} />

          { /* CLINICAL INVESTIGATION - 3 */}
          <Route path="/visits/ci" element={<EditPatientCI />} />

          { /* ANYAvYADHI - 4 */}
          <Route path="/visits/anyavyadhi" element={<EditPatientAnyavyadhi />} />

          { /* KULAWRUTTA - 5 */}
          <Route path="/visits/kulawrutta" element={<EditPatientKulawrutta />} />

          { /* TAPASANYA - 6 */}
          <Route path="/visits/tapasanya" element={<EditPatientTapasyana />} />

          { /* ARTAWAWRUTTA - 7 */}
          <Route path="/visits/artawawrutta" element={<EditPatientArtawawrutta />} />

          { /* DIAGNOSIS - 8 */}
          <Route path="/visits/diagnosis" element={<EditPatientDiagnosis />} />

          { /* TREATMENT - 9 */}
          <Route path="/visits/treatment" element={<EditPatientTreatment />} />

          { /* NEXT VISIT - 10 */}
          <Route path="/visits/nextvisit" element={<EditPatientNextVisit />} />

        </Routes>

      </div>

    </Router>

  );
};


const PatientSearchBox = ({ onSearch }) => {


  const [searchTerm, setSearchTerm] = useState('');


  const clientSearch = () => {
    console.log(searchTerm);
    onSearch(searchTerm);
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search patients by name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button onClick={clientSearch}>Search</button>
    </div>
  );
};

const PatientCaseSelect = ({ patientId, caseId }) => {

  console.log(patientId);
  console.log(caseId);
  const navigate = useNavigate();
  const location = useLocation();

  const handleCaseSelect = (patientId, caseId) => {

    // const navigate = useNavigate();
    navigate(`/visits/${patientId}/${caseId}`, { state: location.state });

  };

  return (

    <div>
      <button onClick={() => handleCaseSelect(patientId, caseId)}>View Visits</button>
    </div>

  );

};

export default App;
