import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormGroup, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import CONFIG from '../../config';



const CheckForm = () => {
  const navigate = useNavigate();

  const initialValues = {
    mobileNumber: '',
    password: ''
};

 // const [mobileNo, setMobileNo] = useState('');
 // const [password, setPassword] = useState('');
  const [ user, setUser ] =useState(null);
  const baseURL = CONFIG.HTTP_PROTCOL + CONFIG.DOMAIN_NAME;


  const validationSchema = Yup.object().shape({
    mobileNumber: Yup.string()
        .required('Mobile number is required')
        .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, 'Invalid mobile number'),
    password: Yup.string()
        .required('Password is required')
});


  const handleSubmit = async (values, actions) => {
    //event.preventDefault();

    const {mobileNumber, password} = values;
    console.log('mobileNumber', mobileNumber);

    try {
      const { data } = await axios.post(`${baseURL}/mandali/check`, {
        mobileNumber,
        password
      });
      if (data.token && mobileNumber === data.userData.MANDALI_MOB_NO) {
        localStorage.setItem('token', data.token);
        setUser({ username: data.userData.MANDALI_MOB_NO, role: data.userData.MANDALI_ROLE });

        if (verifyToken(data.token)) {

          navigate('/patientlist', {state: {checkState: user}});

        }
        else {
          alert('Login failed! Check credentials.');
        }

      } else {
        alert('Login failed! Check credentials.');
      }
    } catch (error) {
      alert('Login error:', error.message);
    }

    actions.setSubmitting(false);

  };


  const verifyToken = async (token) => {
    try {
      const response = await axios.post(`${baseURL}/mandali/checktoken`, {}, {
        headers: {
          'Authorization': token
        }
      });
  
      // Check response status code
      if (response.status === 200) {
        console.log("Token is valid:", response.data);
        // Additional actions based on successful verification can be performed here
        return true;
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error response:', error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Error request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
      }
      return false;
    }
  };

return (
    <div className="form-wrapper">
        <h1>Login</h1>
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
                  {({ errors, touched }) => (

            <Form>
                <div className="form-group">
                    <label htmlFor="mobileNumber">Mobile Number</label>
                    <Field name="mobileNumber" type="text" className={`form-control ${touched.mobileNumber && errors.mobileNumber ? "is-invalid" : ""}`} />
                    <ErrorMessage name="mobileNumber" component="div" className="invalid-feedback" />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Field name="password" type="password" className={`form-control ${touched.password && errors.password ? "is-invalid" : ""}`} />
                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                </div>
                <Button type="submit" variant="primary">Login</Button>

            </Form>
                  )}
        </Formik>
    </div>
);


};

export default CheckForm;
