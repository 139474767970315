// authUtils.js
import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CONFIG from '../../config';

const CheckToken = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const baseURL = CONFIG.HTTP_PROTCOL + CONFIG.DOMAIN_NAME;

 useEffect(() => {

  const token = localStorage.getItem('token');
  
  if (!token) {
    navigate('/check');
  } else {
    axios.post(`${baseURL}}/mandali/checktoken`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      console.log('Token validation successful', response.data);
    })
    .catch(error => {
      console.error('Token validation failed', error);
      navigate('/check');
    });
  }
}, []);

};

export default CheckToken;
