// EditStudent Component for update student data 

// Import Modules 
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import PatientNextVisitForm from "./PatientNextVisitForm";
import CONFIG from '../config';
import AuthUtil from "./Mandali/AuthUtil";

// EditStudent Component 
const EditPatientNextVisit = () => {

  const location = useLocation();

  console.log(location.state)

  const state = location.state || {};

  const [selectedPatientData, setSelectedPatientData] = useState(state.selectedPatientData ? state.selectedPatientData : null);
  const [submissionState, setSubmissionState] = useState(null);
  const baseURL = CONFIG.HTTP_PROTCOL + CONFIG.DOMAIN_NAME;


  console.log('selected Visit in Patient next visit :', selectedPatientData);

  let navigate = useNavigate();

  useEffect(() => {



    if (submissionState?.submitted) {

      console.log(selectedPatientData); // This will log the updated state


      if (selectedPatientData[11] === null) {
        updateExistingCaseVisit(selectedPatientData);

      }
      else if (selectedPatientData[11].flags[0].NEW_PATIENT_FLAG === "Y" || selectedPatientData[11].flags[0].NEW_CASE_FLAG === "Y") {

        addNewCaseVisit(selectedPatientData);

      }
      else {

        addExistingCaseVisit(selectedPatientData);

      }

      navigate('/patientlist');

    }

  });

  const {verifyTokenAndRedirect} = AuthUtil();
    
  useEffect(()=>{

    verifyTokenAndRedirect();

  }, []);


  //onSubmit handler 
  const onSubmitHandler = (patientNextVisit) => {

    //alert("On Submit");

    console.log("patient data after submit:", patientNextVisit); // Log the data to be updated

    console.log("selected Visit after submit:", selectedPatientData); // Log the data to be updated

    // Update the selectedVisit object in memory
    setSelectedPatientData((tempVisit) => {
      const updatedPatientData = {
        ...tempVisit[10].patientNextVisit[0],
        ...patientNextVisit,
      };

      console.log('updated patient info: ', updatedPatientData);

      const flags = tempVisit[11] ? tempVisit[11] : null;

      let prevSelectedVisit = [...tempVisit.slice(0, 10), { patientNextVisit: [updatedPatientData] }, flags];

      //navigate("/visits/symptoms", { state: { prevSelectedVisit } });

      console.log('patient data after next visit updation', prevSelectedVisit);

      return prevSelectedVisit;

    });

    //   navigate("/visits/hostory", { state: { selectedPatientData } });

    console.log(selectedPatientData);

    setSubmissionState({ submitted: true });

  };

  const updateExistingCaseVisit = async (prevSelectedVisit) => {
    try {

      console.log('prev selected visit', prevSelectedVisit);
      const patientInfo = prevSelectedVisit[0].patientInfo[0];
      const patientSymptoms = prevSelectedVisit[1].patientSymptoms[0];
      const patientHistory = prevSelectedVisit[2].patientHistory[0];
      const patientClinicalInvestigation = prevSelectedVisit[3].patientClinicalInvestigation[0];
      const patientAnyavyadhi = prevSelectedVisit[4].patientAnyavyadhi[0];
      const patientKulawrutta = prevSelectedVisit[5].patientKulawrutta[0];
      const patientTapasanya = prevSelectedVisit[6].patientTapasanya[0];
      const patientArtawawrutta = prevSelectedVisit[7].patientArtawawrutta[0];
      const patientDiagnosis = prevSelectedVisit[8].patientDiagnosis[0];
      const patientTreatment = prevSelectedVisit[9].patientTreatment[0];
      const patientNextVisit = prevSelectedVisit[10].patientNextVisit[0];
      const patientId = patientSymptoms.PATIENT_ID;
      const caseId = patientSymptoms.CASE_ID;
      const visitId = patientSymptoms.VISIT_ID;

      console.log('patient Info', patientInfo);
      console.log('patient symptoms', patientSymptoms);

      const response = await axios.post(`${baseURL}/visits/addexistingcasevisit`, {
        patientInfo, patientSymptoms, patientHistory, patientClinicalInvestigation, patientAnyavyadhi, patientKulawrutta, patientTapasanya,
        patientArtawawrutta, patientDiagnosis, patientTreatment, patientNextVisit
      });

      console.log('Response:', response.data);
      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error('Error updating patient details:', error.response ? error.response.data : error.message);
      // Handle error (e.g., show an error message)
    }
  };

  const addNewCaseVisit = async (prevSelectedVisit) => {
    try {

      // set case id = 1 and visit id = 1

      console.log('prev selected visit', prevSelectedVisit);
      const patientInfo = prevSelectedVisit[0].patientInfo[0];
      const patientSymptoms = prevSelectedVisit[1].patientSymptoms[0];
      const patientHistory = prevSelectedVisit[2].patientHistory[0];
      const patientClinicalInvestigation = prevSelectedVisit[3].patientClinicalInvestigation[0];
      const patientAnyavyadhi = prevSelectedVisit[4].patientAnyavyadhi[0];
      const patientKulawrutta = prevSelectedVisit[5].patientKulawrutta[0];
      const patientTapasanya = prevSelectedVisit[6].patientTapasanya[0];
      const patientArtawawrutta = prevSelectedVisit[7].patientArtawawrutta[0];
      const patientDiagnosis = prevSelectedVisit[8].patientDiagnosis[0];
      const patientTreatment = prevSelectedVisit[9].patientTreatment[0];
      const patientNextVisit = prevSelectedVisit[10].patientNextVisit[0];
      const patientId = patientSymptoms.PATIENT_ID;
      const caseId = patientSymptoms.CASE_ID;
      const visitId = patientSymptoms.VISIT_ID;

      console.log('patient Info', patientInfo);
      console.log('patient symptoms', patientSymptoms);

      const response = await axios.post(`${baseURL}/visits/addnewcasevisit`, {
        patientInfo, patientSymptoms, patientHistory, patientClinicalInvestigation, patientAnyavyadhi, patientKulawrutta, patientTapasanya,
        patientArtawawrutta, patientDiagnosis, patientTreatment, patientNextVisit
      });

      console.log('Response:', response.data);
      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error('Error updating patient details:', error.response ? error.response.data : error.message);
      // Handle error (e.g., show an error message)
    }
  };


  const addExistingCaseVisit = async (prevSelectedVisit) => {
    try {

      // set case id = 1 and visit id = 1

      console.log('prev selected visit', prevSelectedVisit);
      const patientInfo = prevSelectedVisit[0].patientInfo[0];
      const patientSymptoms = prevSelectedVisit[1].patientSymptoms[0];
      const patientHistory = prevSelectedVisit[2].patientHistory[0];
      const patientClinicalInvestigation = prevSelectedVisit[3].patientClinicalInvestigation[0];
      const patientAnyavyadhi = prevSelectedVisit[4].patientAnyavyadhi[0];
      const patientKulawrutta = prevSelectedVisit[5].patientKulawrutta[0];
      const patientTapasanya = prevSelectedVisit[6].patientTapasanya[0];
      const patientArtawawrutta = prevSelectedVisit[7].patientArtawawrutta[0];
      const patientDiagnosis = prevSelectedVisit[8].patientDiagnosis[0];
      const patientTreatment = prevSelectedVisit[9].patientTreatment[0];
      const patientNextVisit = prevSelectedVisit[10].patientNextVisit[0];
      const patientId = patientSymptoms.PATIENT_ID;
      const caseId = patientSymptoms.CASE_ID;
      const visitId = patientSymptoms.VISIT_ID;

      console.log('patient Info', patientInfo);
      console.log('patient symptoms', patientSymptoms);

      const response = await axios.post(`${baseURL}/visits/addexistingcasevisit`, {
        patientInfo, patientSymptoms, patientHistory, patientClinicalInvestigation, patientAnyavyadhi, patientKulawrutta, patientTapasanya,
        patientArtawawrutta, patientDiagnosis, patientTreatment, patientNextVisit
      });

      console.log('Response:', response.data);
      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error('Error updating patient details:', error.response ? error.response.data : error.message);
      // Handle error (e.g., show an error message)
    }
  };

  // Return student form 
  return (

    <div>
      {console.log('inside rendering: ', selectedPatientData)}
      {selectedPatientData && (
        <PatientNextVisitForm
          initialValues={selectedPatientData}
          onSubmit={onSubmitHandler}
          enableReinitialize={true}
        >
          Update Patient
        </PatientNextVisitForm>
      )}
    </div>
  );

};

export default EditPatientNextVisit;

