
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormGroup, Button } from "react-bootstrap";

const PatientCIForm = (props) => {
  const { initialValues, onSubmit } = props;

console.log(props);

  const patientCIInfo = props.initialValues[3].patientClinicalInvestigation[0];

  // Define form's initial values based on the patient clinical investigation data
  const formInitialValues = {
    PATIENT_NADI: patientCIInfo.PATIENT_NADI || "",
    PATIENT_GATI: patientCIInfo.PATIENT_GATI || "",
    PATIENT_JIVA: patientCIInfo.PATIENT_JIVA || "",    
    PATIENT_MAL: patientCIInfo.PATIENT_MAL || "",    
    PATIENT_MUTR: patientCIInfo.PATIENT_MUTR || "",    
    PATIENT_NETRA: patientCIInfo.PATIENT_NETRA || "",
    PATIENT_HRADAY: patientCIInfo.PATIENT_HRADAY || "",
    PATIENT_PHUPPUS: patientCIInfo.PATIENT_PHUPPUS || "",
    PATIENT_NIDRA: patientCIInfo.PATIENT_NIDRA || "",
    PATIENT_AGNI: patientCIInfo.PATIENT_AGNI || "",


    // Add more fields according to your table definition
  };

  // Define validation schema for the form fields
  const validationSchema = Yup.object().shape({
    PATIENT_NADI: Yup.string(),
    PATIENT_GATI: Yup.string(),
    PATIENT_JIVA: Yup.string(),
    PATIENT_MAL: Yup.string(),
    PATIENT_MUTR: Yup.string(),
    PATIENT_NETRA: Yup.string(),
    PATIENT_HRADAY: Yup.string(),
    PATIENT_PHUPPUS: Yup.string(),
    PATIENT_NIDRA: Yup.string(),
    PATIENT_AGNI: Yup.string(),
    // Add validations for additional fields
  });

  return (
    <div className="form-wrapper">
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          onSubmit(values); // Pass form values to the parent component's onSubmit function
          actions.setSubmitting(false);
        }}
      >
        {({errors, touched, isSubmitting }) => (
          <Form>
            <FormGroup>
              <label htmlFor="PATIENT_NADI">Nadi</label>
              <Field name="PATIENT_NADI" type="text" className={`form-control ${touched.PATIENT_NADI && errors.PATIENT_NADI ? "is-invalid" : ""}`} />
              <ErrorMessage name="PATIENT_NADI" component="div" className="invalid-feedback" />
            </FormGroup>

            <FormGroup>
              <label htmlFor="PATIENT_GATI">Gati</label>
              <Field name="PATIENT_GATI" type="text" className={`form-control ${touched.PATIENT_GATI && errors.PATIENT_GATI ? "is-invalid" : ""}`} />
              <ErrorMessage name="PATIENT_GATI" component="div" className="invalid-feedback" />
            </FormGroup>

            <FormGroup>
              <label htmlFor="PATIENT_JIVA">Jiva</label>
              <Field name="PATIENT_JIVA" type="text" className={`form-control ${touched.PATIENT_JIVA && errors.PATIENT_JIVA ? "is-invalid" : ""}`} />
              <ErrorMessage name="PATIENT_JIVA" component="div" className="invalid-feedback" />
            </FormGroup>

            <FormGroup>
              <label htmlFor="PATIENT_MAL">Mal</label>
              <Field name="PATIENT_MAL" type="text" className={`form-control ${touched.PATIENT_MAL && errors.PATIENT_MAL ? "is-invalid" : ""}`} />
              <ErrorMessage name="PATIENT_MAL" component="div" className="invalid-feedback" />
            </FormGroup>

            <FormGroup>
              <label htmlFor="PATIENT_MUTR">Mutr</label>
              <Field name="PATIENT_MUTR" type="text" className={`form-control ${touched.PATIENT_MUTR && errors.PATIENT_MUTR ? "is-invalid" : ""}`} />
              <ErrorMessage name="PATIENT_MUTR" component="div" className="invalid-feedback" />
            </FormGroup>

            <FormGroup>
              <label htmlFor="PATIENT_NETRA">Netra</label>
              <Field name="PATIENT_NETRA" type="text" className={`form-control ${touched.PATIENT_NETRA && errors.PATIENT_NETRA ? "is-invalid" : ""}`} />
              <ErrorMessage name="PATIENT_NETRA" component="div" className="invalid-feedback" />
            </FormGroup>

            <FormGroup>
              <label htmlFor="PATIENT_HRADAY">Hraday</label>
              <Field name="PATIENT_HRADAY" type="text" className={`form-control ${touched.PATIENT_HRADAY && errors.PATIENT_HRADAY ? "is-invalid" : ""}`} />
              <ErrorMessage name="PATIENT_HRADAY" component="div" className="invalid-feedback" />
            </FormGroup>

            <FormGroup>
              <label htmlFor="PATIENT_PHUPPUS">Phuppus</label>
              <Field name="PATIENT_PHUPPUS" type="text" className={`form-control ${touched.PATIENT_PHUPPUS && errors.PATIENT_PHUPPUS ? "is-invalid" : ""}`} />
              <ErrorMessage name="PATIENT_PHUPPUS" component="div" className="invalid-feedback" />
            </FormGroup>

            <FormGroup>
              <label htmlFor="PATIENT_NIDRA">Nidra</label>
              <Field name="PATIENT_NIDRA" type="text" className={`form-control ${touched.PATIENT_NIDRA && errors.PATIENT_NIDRA ? "is-invalid" : ""}`} />
              <ErrorMessage name="PATIENT_NIDRA" component="div" className="invalid-feedback" />
            </FormGroup>

            <FormGroup>
              <label htmlFor="PATIENT_AGNI">Agni</label>
              <Field name="PATIENT_AGNI" type="text" className={`form-control ${touched.PATIENT_AGNI && errors.PATIENT_AGNI ? "is-invalid" : ""}`} />
              <ErrorMessage name="PATIENT_AGNI" component="div" className="invalid-feedback" />
            </FormGroup>



            {/* Add more FormGroup components for additional fields as needed */}

            <Button type="submit" variant="primary" disabled={isSubmitting}>
              Submit
            </Button>
      
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PatientCIForm;
