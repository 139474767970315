import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormGroup, Button } from "react-bootstrap";

const PatientTapasanyaForm = (props) => {

  const { initialValues, onSubmit } = props;

  const patientTapasanyaInfo = props.initialValues[6].patientTapasanya[0];
  
  // Define the form's initial values
  const formInitialValues = {
    VISIT_DATE: patientTapasanyaInfo.VISIT_DATE ? patientTapasanyaInfo.VISIT_DATE.substring(0, 10) : "",
    PATIENT_TAPASANYA: patientTapasanyaInfo.PATIENT_TAPASANYA || "",
    PATIENT_WEIGHT: patientTapasanyaInfo.PATIENT_WEIGHT || "",
    PATIENT_BP: patientTapasanyaInfo.PATIENT_BP || "",
    PATIENT_PULSERATE: patientTapasanyaInfo.PATIENT_PULSERATE || 0,
  };

  // Define the validation schema
  const validationSchema = Yup.object().shape({
  //  PATIENT_TAPASANYA: Yup.string().nullable(true),
    VISIT_DATE: Yup.date(),
    PATIENT_WEIGHT: Yup.string(),
    PATIENT_BP: Yup.string(),
    PATIENT_PULSERATE: Yup.number().integer(),
  });

  return (
    <div className="form-wrapper">
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values); // Pass form values to the parent component's onSubmit function
          setSubmitting(false);
        }}
      >
        <Form>
          <FormGroup>
            <label htmlFor="VISIT_DATE">Visit Date</label>
            <Field name="VISIT_DATE" type="date" className="form-control" />
            <ErrorMessage name="VISIT_DATE" component="div" className="invalid-feedback" />
          </FormGroup>

          <FormGroup>
            <label htmlFor="PATIENT_TAPASANYA">Tapasanya</label>
            <Field name="PATIENT_TAPASANYA" as="textarea" className="form-control" />
            <ErrorMessage name="PATIENT_TAPASANYA" component="div" className="invalid-feedback" />
          </FormGroup>

          <FormGroup>
            <label htmlFor="PATIENT_WEIGHT">Weight</label>
            <Field name="PATIENT_WEIGHT" type="text" className="form-control" />
            <ErrorMessage name="PATIENT_WEIGHT" component="div" className="invalid-feedback" />
          </FormGroup>

          <FormGroup>
            <label htmlFor="PATIENT_BP">Blood Pressure</label>
            <Field name="PATIENT_BP" type="text" className="form-control" />
            <ErrorMessage name="PATIENT_BP" component="div" className="invalid-feedback" />
          </FormGroup>

          <FormGroup>
            <label htmlFor="PATIENT_PULSERATE">Pulse Rate</label>
            <Field name="PATIENT_PULSERATE" type="number" className="form-control" />
            <ErrorMessage name="PATIENT_PULSERATE" component="div" className="invalid-feedback" />
          </FormGroup>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default PatientTapasanyaForm;
