import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormGroup, Button } from "react-bootstrap";

const PatientAnyavyadhiForm = (props) => {

  const { initialValues, onSubmit } = props;

const patientAnyavyadhiInfo = props.initialValues[4].patientAnyavyadhi[0];

  // Define the form's initial values based on the expected structure of initialValues
  const formInitialValues = {
    PATIENT_ANYAVYADHI: patientAnyavyadhiInfo.PATIENT_ANYAVYADHI || "",
    // LAST_UPDATE_DATE might not be editable in the form, so it's not included here
    // LAST_UPDATE_USER might be automatically set by the system, so it's also not included here
  };

  // Define the validation schema for the form fields
  const validationSchema = Yup.object().shape({
    PATIENT_ANYAVYADHI: Yup.string().max(1000, 'Must be 1000 characters or less'),
  });

  return (
    <div className="form-wrapper">
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        <Form>
          <FormGroup>
            <label htmlFor="PATIENT_ANYAVYADHI">Anyavyadhi</label>
            <Field name="PATIENT_ANYAVYADHI" as="textarea" className="form-control" />
            <ErrorMessage name="PATIENT_ANYAVYADHI" component="div" className="invalid-feedback" />
          </FormGroup>

          {/* Additional fields can be added here if needed */}

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default PatientAnyavyadhiForm;
