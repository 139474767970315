// useAuth.js
import { useNavigate, useLocation } from 'react-router-dom';


const AuthUtil = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const verifyTokenAndRedirect = () => {
 //   const userData = location.state?.userData;
 const token = localStorage.getItem('token');

    if (!token) {
      navigate('/'); // navigate to a default route if checks fail
    } 
  };

  return { verifyTokenAndRedirect };
};

export default AuthUtil;
