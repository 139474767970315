// EditStudent Component for update student data 

// Import Modules 
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import AuthUtil from '../Mandali/AuthUtil';

// EditStudent Component 
const AddNewCase = ({selectedPatientId, patientCases, selectedPatient}) => {

    // let { patientId, caseId, visitId } = useParams();

    //  console.log ('in AddPatientInfo :', visitId); 
    const [selectedVisit, setSelectedVisit] = useState(null);
    const [submissionState, setSubmissionState] = useState(null);

    let navigate = useNavigate();
    //const [formValues, setFormValues] = useState({ Student_Name: '', Student_Email: '', Student_Roll_Number: '' })

    const {verifyTokenAndRedirect} = AuthUtil();
    
    useEffect(()=>{
  
      verifyTokenAndRedirect();
  
    }, []);
  


const handleNewCase = async (patient) => {

    // Get Maximum cases of the patients


  const maxCaseId = patientCases.length > 0 ? Math.max(...patientCases.map(caseItem => caseItem.CASE_ID)) : 0;    

    console.log("max case id: ", maxCaseId);

    // Add new case id

const caseId = maxCaseId + 1;
  
const newPatientVisitStructure = [
  {
      "patientInfo": [
          patient
      ]
  },
  {
      "patientSymptoms": [
          {
              "CASE_ID": caseId,
              "PATIENT_ID": selectedPatientId,
              "VISIT_ID": 1,
              "PATIENT_SYMPTOMS": "",
              "VISIT_DATE": "",
              "LAST_UPDATE_DATE": "",
              "LAST_UPDATE_USER": "Doctor",
              "CLINIC_ID": 1,
              "DOCTOR_ID": 1,
              "DELETE_CASE": "N"
          }
      ]
  },
  {
      "patientHistory": [
          {
              "CASE_ID": caseId,
              "PATIENT_ID": selectedPatientId,
              "PATIENT_HISTORY": "",
              "LAST_UPDATE_DATE": "",
              "LAST_UPDATE_USER": "Doctor",
              "CLINIC_ID": 1,
              "DOCTOR_ID": 1
          }
      ]
  },
  {
      "patientClinicalInvestigation": [
          {
              "CASE_ID": caseId,
              "PATIENT_ID": selectedPatientId,
              "VISIT_ID": 1,
              "PATIENT_NADI": "",
              "PATIENT_GATI": "",
              "PATIENT_JIVA": "",
              "PATIENT_MAL": "",
              "PATIENT_MUTR": "",
              "PATIENT_NETRA": "",
              "PATIENT_HRADAY": "",
              "PATIENT_PHUPPUS": "",
              "LAST_UPDATE_DATE": "",
              "LAST_UPDATE_USER": "Doctor",
              "PATIENT_NIDRA": "",
              "PATIENT_AGNI": "",
              "CLINIC_ID": 1,
              "DOCTOR_ID": 1
          }
      ]
  },
  {
      "patientAnyavyadhi": [
          {
              "CASE_ID": caseId,
              "PATIENT_ID": selectedPatientId,
              "VISIT_ID": 1,
              "PATIENT_ANYAVYADHI": "",
              "LAST_UPDATE_DATE": "",
              "LAST_UPDATE_USER": "Doctor",
              "CLINIC_ID": 1,
              "DOCTOR_ID": 1
          }
      ]
  },
  {
      "patientKulawrutta": [
          {
              "CASE_ID": caseId,
              "PATIENT_ID": selectedPatientId,
              "PATIENT_KULAWRUTTA": "",
              "LAST_UPDATE_DATE": "",
              "LAST_UPDATE_USER": "Doctor",
              "CLINIC_ID": 1,
              "DOCTOR_ID": 1
          }
      ]
  },
  {
      "patientTapasanya": [
          {
              "CASE_ID": caseId,
              "PATIENT_ID": selectedPatientId,
              "VISIT_ID": 1,
              "VISIT_DATE": "",
              "PATIENT_TAPASANYA": "",
              "LAST_UPDATE_DATE": "",
              "LAST_UPDATE_USER": "Doctor",
              "CLINIC_ID": 1,
              "DOCTOR_ID": 1,
              "PATIENT_WEIGHT": "",
              "PATIENT_BP": "",
              "PATIENT_PULSERATE": ""
          }
      ]
  },
  {
      "patientArtawawrutta": [
          {
              "CASE_ID": caseId,
              "PATIENT_ID": selectedPatientId,
              "VISIT_ID": 1,
              "VISIT_DATE": "",
              "DIAGNOSIS_DETAILS": "",
              "LAST_UPDATED_TIME": "",
              "LAST_USER": "Doctor",
              "CLINIC_ID": 1,
              "DOCTOR_ID": 1
          }
      ]
  },
  {
      "patientDiagnosis": [
          {
              "CASE_ID": caseId,
              "PATIENT_ID": selectedPatientId,
              "VISIT_ID": 1,
              "NIDAN_DETAILS": "",
              "LAST_UPDATE_DATE": "",
              "LAST_UPDATE_USER": "Doctor",
              "CLINIC_ID": 1,
              "DOCTOR_ID": 1
          }
      ]
  },
  {
      "patientTreatment": [
          {
              "CASE_ID": caseId,
              "PATIENT_ID": selectedPatientId,
              "VISIT_ID": 1,
              "TREATMENT_DETAILS": "",
              "LAST_UPDATE_DATE": "",
              "LAST_UPDATE_USER": "Doctor",
              "DIVAS": 30,
              "CLINIC_ID": 1,
              "DOCTOR_ID": 1
          }
      ]
  },
  {
      "patientNextVisit": [
          {
              "CASE_ID": caseId,
              "PATIENT_ID": selectedPatientId,
              "VISIT_ID": 1,
              "NEXT_VISIT_DATE": "",
              "NEXT_VISIT_TIME": "",
              "NEXT_VISIT_DAY": "",
              "LAST_UPDATE_DATE": "",
              "LAST_UPDATE_USER": "Doctor"
          }
      ]
  },
  {
      "flags": [
          {
              "NEW_PATIENT_FLAG": "N",
              "NEW_CASE_FLAG": "Y"
          }
      ]
  }
];

  console.log(newPatientVisitStructure);
  console.log(selectedPatient);
  console.log(caseId);    

const prevSelectedVisit = newPatientVisitStructure;

  navigate("/visits/symptoms", { state: { prevSelectedVisit } });
    // direct to edit patient info with the selected patient details and the case id.

};


return (

    <div>
                          
    <button onClick={() => handleNewCase(selectedPatient)}>Add Case</button>


</div>


);

};

export default AddNewCase;