// EditStudent Component for update student data 

// Import Modules 
import React, { useState, useEffect } from "react"; 
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios"; 
import PatientInfoForm from "./PatientInfoForm"; 
import CONFIG from '../config';
import AuthUtil from "./Mandali/AuthUtil";

// EditStudent Component 
const EditPatientInfo = () => {

  let { patientId, caseId, visitId } = useParams();

  console.log ('in EditPatientInfo :', visitId); 
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [checkState, setCheckState] = useState(null);
  const baseURL = CONFIG.HTTP_PROTCOL + CONFIG.DOMAIN_NAME;

	let navigate = useNavigate();
	//const [formValues, setFormValues] = useState({ Student_Name: '', Student_Email: '', Student_Roll_Number: '' })
	
  useEffect(() => {
    console.log ('in use effect');
    console.log('visitId in useEffect:', visitId);
      
      axios.get(`${baseURL}/visits/${patientId}/${caseId}/${visitId}`)
      .then((res) => {
   
        const data = res.data;
        setSelectedVisit(data); // Assuming the endpoint returns an array of visits
          
        
      })
      .catch((err) => {
        console.error('Error fetching patient visits:', err);
      });

  }, []);


  const {verifyTokenAndRedirect} = AuthUtil();
    
  useEffect(()=>{

    verifyTokenAndRedirect();

  }, []);


//onSubmit handler 
const onSubmitHandler = (patientData) => { 

  //alert("On Submit");
 
  console.log("patient data after submit:", patientData); // Log the data to be updated

  console.log("selected Visit after submit:", selectedVisit); // Log the data to be updated

  // Update the selectedVisit object in memory
  setSelectedVisit((prevSelectedVisit) => {
    const updatedPatientInfo = {
      ...prevSelectedVisit[0].patientInfo[0],
      ...patientData,
    };

    console.log('updated patient info: ', updatedPatientInfo);

    /*

    const updatedSelectedVisit = {
      ...prevSelectedVisit,
      patientInfo: prevSelectedVisit[0].patientInfo.map(info => 
        info.PATIENT_ID === updatedPatientInfo.PATIENT_ID ? { ...info, ...updatedPatientInfo } : info
      ),  
    };

    */

   prevSelectedVisit.shift();
   prevSelectedVisit.unshift({patientInfo: [updatedPatientInfo]});   

    navigate("/visits/symptoms", { state: { prevSelectedVisit: prevSelectedVisit, check: checkState } });

    console.log(prevSelectedVisit);

    return prevSelectedVisit;

	  });
  };

  
// Return student form 
return ( 

  <div>
  {console.log('inside rendering: ', selectedVisit)}
  {selectedVisit && (
	<PatientInfoForm 
  initialValues = {selectedVisit} 
	onSubmit={onSubmitHandler} 
	enableReinitialize = {true}
	> 
	Update Patient 
	</PatientInfoForm> 
  )}
</div>
  ); 


}; 

// Export EditStudent Component 
export default EditPatientInfo;
