import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormGroup, Button } from "react-bootstrap";

const PatientDiagnosisForm = (props) => {

  const { initialValues, onSubmit } = props;

  const patientDiagnosisInfo = props.initialValues[8].patientDiagnosis[0];
 

  // Define the form's initial values based on the patient diagnosis data
  const formInitialValues = {
    NIDAN_DETAILS: patientDiagnosisInfo.NIDAN_DETAILS || "",
    // Fields like LAST_UPDATE_DATE and LAST_UPDATE_USER might be handled automatically
  };

  // Define the validation schema for the form fields
  const validationSchema = Yup.object().shape({
    NIDAN_DETAILS: Yup.string(), // Ensuring that diagnosis details are provided
  });

  return (
    <div className="form-wrapper">
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values); // Pass form values to the parent component's onSubmit function
          setSubmitting(false);
        }}
      >
        <Form>
          <FormGroup>
            <label htmlFor="NIDAN_DETAILS">Nidan Details</label>
            <Field name="NIDAN_DETAILS" as="textarea" className="form-control" />
            <ErrorMessage name="NIDAN_DETAILS" component="div" className="invalid-feedback" />
          </FormGroup>

          {/* Additional form groups for other fields can be added here if needed */}

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default PatientDiagnosisForm;
