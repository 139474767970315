import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AddNewVisit from './Add/AddNewVisit';
import AuthUtil from './Mandali/AuthUtil';

// Import from react-router-dom
import {
  BrowserRouter as Router, Routes,
  Route, Link
} from "react-router-dom";

import CONFIG from '../config';

import { Nav, Navbar, Container, Row, Col }
  from "react-bootstrap";

const PatientVisits = () => {
  const { patientId, caseId } = useParams();
  const [visits, setVisits] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const baseURL = CONFIG.HTTP_PROTCOL + CONFIG.DOMAIN_NAME;

  // onSelectCase(patientId, caseId);

  const {verifyTokenAndRedirect} = AuthUtil();
    
  useEffect(()=>{

    verifyTokenAndRedirect();

  }, []);


  useEffect(() => {
    const fetchVisits = async () => {
      try {
        const response = await axios.get(`${baseURL}/visits/${patientId}/${caseId}`);
        setVisits(response.data); // Assuming the endpoint returns an array of visits
      } catch (error) {
        console.error('Error fetching patient visits:', error);
      }
    };

    if (caseId) {
      fetchVisits();
    }
  }, [caseId]);

  const handleVisitSelect = (visitId) => {

    // const navigate = useNavigate();
    navigate(`/visits/${patientId}/${caseId}/${visitId}`, { state: location.state });

  };

  return (

    <Container>
      <Row>
        <Col md={12} align="right">

          <div>

            <AddNewVisit patientVisits = {visits.map(visit => visit.VISIT_ID)} patientId = {patientId} caseId = {caseId} />
           {/* <button onClick={() => handleNewVisit(patientId, caseId)}>Add Visit</button> */}
    

          </div>

        </Col>

      </Row>
      <Row>
        <Col md={12}>

          <div className="patient-visits-container">
            <h3>Patient ID: {patientId}</h3>
            <h4>Case ID: {caseId}</h4>
            <ul>
              {visits.map(visit => (
                //ps.VISIT_ID, ps.PATIENT_SYMPTOMS, pd.NIDAN_DETAILS, pt.TREATMENT_DETAILS
                <li key={visit.VISIT_ID} onClick={() => handleVisitSelect(visit.VISIT_ID)}>
                  {visit.VISIT_ID} - {visit.PATIENT_SYMPTOMPS} - {visit.NIDAN_DETAILS} - {visit.TREATMENT_DETAILS}
                </li>
              ))}
            </ul>
            <Button onClick={() => navigate(-1)}>Back</Button>
          </div>
        </Col>
      </Row>

    </Container>

  );
};

export default PatientVisits;