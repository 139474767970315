import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormGroup, Button } from "react-bootstrap";

const PatientArtawawruttaForm = (props) => {

  const { initialValues, onSubmit } = props;

  const patientArtawawruttaInfo = props.initialValues[7].patientArtawawrutta[0];
 

  // Define form's initial values based on the patient artawawrutta data
  const formInitialValues = {
    VISIT_DATE: patientArtawawruttaInfo.VISIT_DATE ? patientArtawawruttaInfo.VISIT_DATE.substring(0, 10) : "",
    DIAGNOSIS_DETAILS: patientArtawawruttaInfo.DIAGNOSIS_DETAILS || "",
  };

  // Define validation schema for form fields
  const validationSchema = Yup.object().shape({
    VISIT_DATE: Yup.date(),
//    DIAGNOSIS_DETAILS: Yup.string().required("Diagnosis details are required"),
  });

  return (
    <div className="form-wrapper">
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values); // Pass form values to the parent component's onSubmit function
          setSubmitting(false);
        }}
      >
        <Form>
          <FormGroup>
            <label htmlFor="VISIT_DATE">Visit Date</label>
            <Field name="VISIT_DATE" type="date" className="form-control" />
            <ErrorMessage name="VISIT_DATE" component="div" className="invalid-feedback" />
          </FormGroup>

          <FormGroup>
            <label htmlFor="DIAGNOSIS_DETAILS">Artawawrtta Details</label>
            <Field name="DIAGNOSIS_DETAILS" as="textarea" className="form-control" />
            <ErrorMessage name="DIAGNOSIS_DETAILS" component="div" className="invalid-feedback" />
          </FormGroup>

          {/* Additional fields can be added here as needed */}

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default PatientArtawawruttaForm;
