// EditStudent Component for update student data 

// Import Modules 
import React, { useState, useEffect } from "react"; 
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios"; 
import MandaliInfoForm from "./MandaliInfoForm"; 
import CONFIG from '../../config';
import AuthUtil from "../Mandali/AuthUtil";

// EditStudent Component 
const EditMandaliInfo = () => {

  let { patientId, caseId, visitId } = useParams();

  const [mandaliInfo, setMandaliInfo] = useState(null);
  const [submissionState, setSubmissionState] = useState(null);
  const baseURL = CONFIG.HTTP_PROTCOL + CONFIG.DOMAIN_NAME;
  
	let navigate = useNavigate();
	//const [formValues, setFormValues] = useState({ Student_Name: '', Student_Email: '', Student_Roll_Number: '' })

  const {verifyTokenAndRedirect} = AuthUtil();
    
  useEffect(()=>{

    verifyTokenAndRedirect();

  }, []);

	
  useEffect(() => {
    console.log ('in use effect');
    const mandali_mob_no = '9422279881';

    if (submissionState?.submitted) {

        updateMandaliData(mandaliInfo);

    }

      
      axios.get(`${baseURL}/mandali/getmandali/${mandali_mob_no}`)
      .then((res) => {
   
        const data = res.data;
        setMandaliInfo(data); // Assuming the endpoint returns an array of visits

        
      })
      .catch((err) => {
        console.error('Error fetching patient visits:', err);
      });


  }, [submissionState]);




//onSubmit handler 
const onSubmitHandler = (mandaliData) => { 

  //alert("On Submit");
 
  console.log("patient data after submit:", mandaliData); // Log the data to be updated

  //console.log("selected Visit after submit:", selectedVisit); // Log the data to be updated

  // Update the selectedVisit object in memory
  setMandaliInfo(mandaliData);

  setSubmissionState({submitted: true});

return mandaliData;

  };

const updateMandaliData = async (mandaliInfo) =>{


try {

    console.log('mandali Info', mandaliInfo);

   const response = await axios.put(`${baseURL}/mandali/updatemandali/${mandaliInfo.MANDALI_MOB_NO}`, {
      mandaliInfo
    });

    console.log('Response:', response.data);
    // Handle success (e.g., show a success message)
  } catch (error) {
    console.error('Error updating patient details:', error.response ? error.response.data : error.message);
    // Handle error (e.g., show an error message)
  }

// call backend function to update data

};

  
// Return student form 
return ( 

  <div>
  {console.log('inside rendering: ', mandaliInfo)}
  {mandaliInfo && (
	<MandaliInfoForm 
  initialValues = {mandaliInfo} 
	onSubmit={onSubmitHandler} 
	enableReinitialize = {true}
	> 
	Update Mandali
	</MandaliInfoForm> 
  )}
</div>
  ); 


}; 

// Export EditStudent Component 
export default EditMandaliInfo;
