import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormGroup, Button } from "react-bootstrap";

const PatientHistoryForm = (props) => {
  const { initialValues, onSubmit } = props;

  console.log(props);

  const patientHistoryInfo = props.initialValues[2].patientHistory[0]; 

  console.log('PatientHistoryInfo', patientHistoryInfo);

  const formInitialValues = {
    PATIENT_HISTORY: patientHistoryInfo.PATIENT_HISTORY || "",
  //  LAST_UPDATE_DATE: patientHistoryInfo.LAST_UPDATE_DATE ? patientHistoryInfo.LAST_UPDATE_DATE.substring(0, 10) : "",
    // LAST_UPDATE_USER and other fields not editable by the form can be omitted or handled separately
  };

  const validationSchema = Yup.object().shape({
    PATIENT_HISTORY: Yup.string(),
   // LAST_UPDATE_DATE: Yup.date(),
    // Add validations for other fields as needed
  });

  return (
    <div className="form-wrapper">
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          onSubmit(values); // Make sure to handle the submission in the parent component
          actions.setSubmitting(false);
        }}
      >
        <Form>
          <FormGroup>
            <label htmlFor="PATIENT_HISTORY">Patient History</label>
            <Field name="PATIENT_HISTORY" as="textarea" className="form-control" />
            <ErrorMessage name="PATIENT_HISTORY" component="div" className="invalid-feedback" />
          </FormGroup>
          {/* You can add more fields as needed */}
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default PatientHistoryForm;
