import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormGroup, Button } from "react-bootstrap";

const PatientNextVisitForm = (props) => {

  const { initialValues, onSubmit } = props;

  const patientNextvisitInfo = props.initialValues[10].patientNextVisit[0];


  // Define the form's initial values based on the patient next visit data
  const formInitialValues = {
    NEXT_VISIT_DATE: patientNextvisitInfo.NEXT_VISIT_DATE ? patientNextvisitInfo.NEXT_VISIT_DATE.substring(0, 10) : "",
    NEXT_VISIT_TIME: patientNextvisitInfo.NEXT_VISIT_TIME || "",
    NEXT_VISIT_DAY: patientNextvisitInfo.NEXT_VISIT_DAY || "",
  };

  // Define the validation schema for the form fields
  const validationSchema = Yup.object().shape({
    NEXT_VISIT_DATE: Yup.date().nullable(true),
    NEXT_VISIT_TIME: Yup.string().nullable(true),
    NEXT_VISIT_DAY: Yup.string().nullable(true),
  });

  return (
    <div className="form-wrapper">
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values); // Pass form values to the parent component's onSubmit function
          setSubmitting(false);
        }}
      >
        <Form>
          <FormGroup>
            <label htmlFor="NEXT_VISIT_DATE">Next Visit Date</label>
            <Field name="NEXT_VISIT_DATE" type="date" className="form-control" />
            <ErrorMessage name="NEXT_VISIT_DATE" component="div" className="invalid-feedback" />
          </FormGroup>

          <FormGroup>
            <label htmlFor="NEXT_VISIT_TIME">Next Visit Time</label>
            <Field name="NEXT_VISIT_TIME" type="text" className="form-control" />
            <ErrorMessage name="NEXT_VISIT_TIME" component="div" className="invalid-feedback" />
          </FormGroup>

          <FormGroup>
            <label htmlFor="NEXT_VISIT_DAY">Next Visit Day</label>
            <Field name="NEXT_VISIT_DAY" type="text" className="form-control" />
            <ErrorMessage name="NEXT_VISIT_DAY" component="div" className="invalid-feedback" />
          </FormGroup>

          {/* Additional form groups for other fields can be added here if needed */}

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default PatientNextVisitForm;
