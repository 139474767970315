import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormGroup, Button } from "react-bootstrap";

const PatientSymptomsForm = (props) => {
  const { initialValues, onSubmit } = props;

  console.log(props);

  const patientSymptomsInfo = props.initialValues[1].patientSymptoms[0];

console.log('patientSymptomsInfo', patientSymptomsInfo);

  const formInitialValues = {
    PATIENT_SYMPTOMS: patientSymptomsInfo.PATIENT_SYMPTOMS || "",
    VISIT_DATE: patientSymptomsInfo.VISIT_DATE ? patientSymptomsInfo.VISIT_DATE.substring(0, 10) : "",
    // Other fields can be added here based on your requirements
  };

  const validationSchema = Yup.object().shape({
    PATIENT_SYMPTOMS: Yup.string(),
//    VISIT_DATE: Yup.date(),
    // Add validations for other fields as needed
  });

  return (
    <div className="form-wrapper">
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          onSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        <Form>
          <FormGroup>
            <label htmlFor="PATIENT_SYMPTOMS">Symptoms</label>
            <Field name="PATIENT_SYMPTOMS" as="textarea" className="form-control" />
            <ErrorMessage name="PATIENT_SYMPTOMS" component="div" className="invalid-feedback" />
          </FormGroup>
          <FormGroup>
            <label htmlFor="VISIT_DATE">Visit Date</label>
            <Field name="VISIT_DATE" type="date" className="form-control" />
            <ErrorMessage name="VISIT_DATE" component="div" className="invalid-feedback" />
          </FormGroup>
          {/* Add more fields as needed */}
          <Button variant="primary" type="submit">
            Next
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default PatientSymptomsForm;
