import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormGroup, Button } from "react-bootstrap";

const PatientInfoForm = ( props ) => {

  console.log(props);

const {selectedVisit, onSubmit, enableReinitialize} = props;

	//console.log('selected Visit info', selectedVisit);

  console.log (props);

  let patientInfo = null;

  if (props.initialValues)
  {
  patientInfo = props.initialValues[0].patientInfo[0];
	console.log('Patient info', patientInfo);
}
  
	const initialValues = {
    PATIENT_NAME: patientInfo?.PATIENT_NAME || "",
    PATIENT_AGE: patientInfo?.PATIENT_AGE || "",
    PATIENT_ADDRESS: patientInfo?.PATIENT_ADDRESS || "",
    PATIENT_PHONE: patientInfo?.PATIENT_PHONE || "",
    PATIENT_OCCUPATION: patientInfo?.PATIENT_OCCUPATION || "",
    EMAILID: patientInfo?.EMAILID || "",
    PATIENT_GENDER: patientInfo?.PATIENT_GENDER || "",
    PATIENT_DOB: patientInfo?.PATIENT_DOB ? patientInfo.PATIENT_DOB.substring(0, 10) : "",  // Format for input type="date"
    PATIENT_BLOODGRP: patientInfo?.PATIENT_BLOODGRP || "",
    PATIENT_MOBILE_NUM: patientInfo?.PATIENT_MOBILE_NUM || "",
  };

  

  const validationSchema = Yup.object().shape({
    PATIENT_NAME: Yup.string().required("Required"),
    PATIENT_AGE: Yup.number().positive("Invalid age").integer("Invalid age"),
    PATIENT_PHONE: Yup.string(),
    EMAILID: Yup.string(),
//    PATIENT_DOB: Yup.date(),
    // Add validations for other fields as needed
  });

  return (
    <div className="form-wrapper">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values, actions) => {
        console.log(values);
        props.onSubmit(values);
        actions.setSubmitting(false);
      }}>
        <Form>
          <FormGroup>
            <label htmlFor="PATIENT_NAME">Name</label>
            <Field name="PATIENT_NAME" type="text" className="form-control" />
            <ErrorMessage name="PATIENT_NAME" component="div" className="invalid-feedback" />
          </FormGroup>
          <FormGroup>
            <label htmlFor="PATIENT_AGE">Age</label>
            <Field name="PATIENT_AGE" type="number" className="form-control" />
            <ErrorMessage name="PATIENT_AGE" component="div" className="invalid-feedback" />
          </FormGroup>
          <FormGroup>
            <label htmlFor="PATIENT_PHONE">Phone</label>
            <Field name="PATIENT_PHONE" type="text" className="form-control" />
            <ErrorMessage name="PATIENT_PHONE" component="div" className="invalid-feedback" />
          </FormGroup>
          <FormGroup>
            <label htmlFor="EMAILID">Email</label>
            <Field name="EMAILID" type="email" className="form-control" />
            <ErrorMessage name="EMAILID" component="div" className="invalid-feedback" />
          </FormGroup>
          <FormGroup>
            <label htmlFor="PATIENT_DOB">Date of Birth</label>
            <Field name="PATIENT_DOB" type="date" className="form-control" />
            <ErrorMessage name="PATIENT_DOB" component="div" className="invalid-feedback" />
          </FormGroup>
          {/* Add more fields as needed */}
          <Button variant="primary" type="submit">
            Next
          </Button>
        </Form>
      </Formik>
    </div>
  );

};

export default PatientInfoForm;
