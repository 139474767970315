// EditStudent Component for update student data 

// Import Modules 
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import CONFIG from '../../config';
import AuthUtil from "../Mandali/AuthUtil";

// EditStudent Component 
const AddNewVisit = (props) => {

    console.log ("visits : ", props);

    console.log (Math.max(...props.patientVisits));

    const patientId = props.patientId;
    const caseId = props.caseId;

    const maxVisitId = props.patientVisits.length > 0 ? Math.max(...props.patientVisits) : 0;

    const newVisitId = maxVisitId + 1;

    console.log ("new visit id:", newVisitId);

    const [patientData, setPatientData] = useState(null);
    const [submissionState, setSubmissionState] = useState(null);
    const baseURL = CONFIG.HTTP_PROTCOL + CONFIG.DOMAIN_NAME;

    let navigate = useNavigate();

    const {verifyTokenAndRedirect} = AuthUtil();
    
    useEffect(()=>{
  
      verifyTokenAndRedirect();
  
    }, []);
  

useEffect(() => {

        if(submissionState?.submitted && patientData)
        {

            const patientInfo = patientData[0].patientInfo[0];
            const patientHistory = patientData[1].patientHistory[0];
            const patientKulawrutta = patientData[2].patientKulawrutta[0];
    
    
            const newPatientVisitStructure = [
                {
                    "patientInfo": [
                        patientInfo
                    ]
                },
                {
                    "patientSymptoms": [
                        {
                            "CASE_ID": caseId,
                            "PATIENT_ID": patientId,
                            "VISIT_ID": newVisitId,
                            "PATIENT_SYMPTOMS": "",
                            "VISIT_DATE": "",
                            "LAST_UPDATE_DATE": "",
                            "LAST_UPDATE_USER": "Doctor",
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1,
                            "DELETE_CASE": "N"
                        }
                    ]
                },
                {
                    "patientHistory": [
                        patientHistory
                    ]
                },
                {
                    "patientClinicalInvestigation": [
                        {
                            "CASE_ID": caseId,
                            "PATIENT_ID": patientId,
                            "VISIT_ID": newVisitId,
                            "PATIENT_NADI": "",
                            "PATIENT_GATI": "",
                            "PATIENT_JIVA": "",
                            "PATIENT_MAL": "",
                            "PATIENT_MUTR": "",
                            "PATIENT_NETRA": "",
                            "PATIENT_HRADAY": "",
                            "PATIENT_PHUPPUS": "",
                            "LAST_UPDATE_DATE": "",
                            "LAST_UPDATE_USER": "Doctor",
                            "PATIENT_NIDRA": "",
                            "PATIENT_AGNI": "",
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1
                        }
                    ]
                },
                {
                    "patientAnyavyadhi": [
                        {
                            "CASE_ID": caseId,
                            "PATIENT_ID": patientId,
                            "VISIT_ID": newVisitId,
                            "PATIENT_ANYAVYADHI": "",
                            "LAST_UPDATE_DATE": "",
                            "LAST_UPDATE_USER": "Doctor",
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1
                        }
                    ]
                },
                {
                    "patientKulawrutta": [
                        patientKulawrutta
                    ]
                },
                {
                    "patientTapasanya": [
                        {
                            "CASE_ID": caseId,
                            "PATIENT_ID": patientId,
                            "VISIT_ID": newVisitId,
                            "VISIT_DATE": "",
                            "PATIENT_TAPASANYA": "",
                            "LAST_UPDATE_DATE": "",
                            "LAST_UPDATE_USER": "Doctor",
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1,
                            "PATIENT_WEIGHT": "",
                            "PATIENT_BP": "",
                            "PATIENT_PULSERATE": ""
                        }
                    ]
                },
                {
                    "patientArtawawrutta": [
                        {
                            "CASE_ID": caseId,
                            "PATIENT_ID": patientId,
                            "VISIT_ID": newVisitId,
                            "VISIT_DATE": "",
                            "DIAGNOSIS_DETAILS": "",
                            "LAST_UPDATED_TIME": "",
                            "LAST_USER": "Doctor",
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1
                        }
                    ]
                },
                {
                    "patientDiagnosis": [
                        {
                            "CASE_ID": caseId,
                            "PATIENT_ID": patientId,
                            "VISIT_ID": newVisitId,
                            "NIDAN_DETAILS": "",
                            "LAST_UPDATE_DATE": "",
                            "LAST_UPDATE_USER": "Doctor",
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1
                        }
                    ]
                },
                {
                    "patientTreatment": [
                        {
                            "CASE_ID": caseId,
                            "PATIENT_ID": patientId,
                            "VISIT_ID": newVisitId,
                            "TREATMENT_DETAILS": "",
                            "LAST_UPDATE_DATE": "",
                            "LAST_UPDATE_USER": "Doctor",
                            "DIVAS": 30,
                            "CLINIC_ID": 1,
                            "DOCTOR_ID": 1
                        }
                    ]
                },
                {
                    "patientNextVisit": [
                        {
                            "CASE_ID": caseId,
                            "PATIENT_ID": patientId,
                            "VISIT_ID": newVisitId,
                            "NEXT_VISIT_DATE": "",
                            "NEXT_VISIT_TIME": "",
                            "NEXT_VISIT_DAY": "",
                            "LAST_UPDATE_DATE": "",
                            "LAST_UPDATE_USER": "Doctor"
                        }
                    ]
                }
            ];
    
            console.log(newPatientVisitStructure);
            console.log(patientId);
            console.log(caseId);
    
            const prevSelectedVisit = newPatientVisitStructure;
    
            navigate("/visits/symptoms", { state: { prevSelectedVisit } });
            // direct to edit patient info with the selected patient details and the case id.

        }

}, [submissionState, patientData]);


    const handleNewVisit = async (newVisitId, patientId, caseId) => {

        console.log("max case id: ", newVisitId);

        // Get PatientInfo, History and Kulawrutta details and update the structure with the objects as well as set new visit id in other objects

        try {
            const response = await axios.get(`${baseURL}/patients/getpatienthistory/${patientId}/${caseId}`);
            setPatientData(response.data); // Assuming the endpoint returns an array of visits
            console.log(patientData);

        } catch (error) {
            console.error('Error fetching patient visits:', error);
        }

        setSubmissionState({submitted : true});

    };


    return (

        <div>

            <button onClick={() => handleNewVisit(newVisitId, props.patientId, props.caseId)}>Add Visit</button>


        </div>


    );

};

export default AddNewVisit;