import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormGroup, Button } from "react-bootstrap";

const PatientTreatmentForm = (props) => {

  const { initialValues, onSubmit } = props;

  const patientTreatmentInfo = props.initialValues[9].patientTreatment[0];


  // Define the form's initial values based on the patient treatment data
  const formInitialValues = {
    TREATMENT_DETAILS: patientTreatmentInfo.TREATMENT_DETAILS || "",
    DIVAS: patientTreatmentInfo.DIVAS || "",
    // Fields like LAST_UPDATE_DATE and LAST_UPDATE_USER might be automatically handled
  };

  // Define the validation schema for the form fields
  const validationSchema = Yup.object().shape({
    TREATMENT_DETAILS: Yup.string(),
    DIVAS: Yup.number().integer().nullable(true),
    // Ensure DIVAS (days) is an integer or can be empty
  });

  return (
    <div className="form-wrapper">
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values); // Pass form values to the parent component's onSubmit function
          setSubmitting(false);
        }}
      >
        <Form>
          <FormGroup>
            <label htmlFor="TREATMENT_DETAILS">Treatment Details</label>
            <Field name="TREATMENT_DETAILS" as="textarea" className="form-control" />
            <ErrorMessage name="TREATMENT_DETAILS" component="div" className="invalid-feedback" />
          </FormGroup>

          <FormGroup>
            <label htmlFor="DIVAS">Days (Optional)</label>
            <Field name="DIVAS" type="number" className="form-control" />
            <ErrorMessage name="DIVAS" component="div" className="invalid-feedback" />
          </FormGroup>

          {/* Additional form groups for other fields can be added here if needed */}

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default PatientTreatmentForm;
