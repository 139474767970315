
// EditStudent Component for update student data 

// Import Modules 
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import PatientCIForm from "./PatientCIForm";
import CONFIG from '../config';
import AuthUtil from "./Mandali/AuthUtil";

// EditStudent Component 
const EditPatientCI = () => {

  const location = useLocation();

  console.log(location.state)

  const state = location.state || {};

  const [selectedPatientData, setSelectedPatientData] = useState(state.selectedPatientData ? state.selectedPatientData : null);
  const [submissionState, setSubmissionState] = useState(null);
  const baseURL = CONFIG.HTTP_PROTCOL + CONFIG.DOMAIN_NAME;

  console.log('selected Visit in Patient Symptoms :', selectedPatientData);

  let navigate = useNavigate();

  const {verifyTokenAndRedirect} = AuthUtil();
    
  useEffect(()=>{

    verifyTokenAndRedirect();

  }, []);


  useEffect(() => {

    if(submissionState?.submitted)
    {
    console.log(selectedPatientData); // This will log the updated state
    navigate("/visits/anyavyadhi", { state: { selectedPatientData } });
  }

  }, [selectedPatientData]); 


  //onSubmit handler 
  const onSubmitHandler = (patientCI) => {

 //alert("On Submit");
 
 console.log("patient data after submit:", patientCI); // Log the data to be updated

 console.log("selected Visit after submit:", selectedPatientData); // Log the data to be updated

 // Update the selectedVisit object in memory
 setSelectedPatientData((tempVisit) => {
   const updatedPatientData = {
     ...tempVisit[3].patientClinicalInvestigation[0],
     ...patientCI,
   };

   console.log('updated patient info: ', updatedPatientData);

   let prevSelectedVisit = [...tempVisit.slice(0,3), {patientClinicalInvestigation: [updatedPatientData]}, ...tempVisit.slice(4)];

   //navigate("/visits/symptoms", { state: { prevSelectedVisit } });

   console.log('patient data after Clinical Investigation updation',prevSelectedVisit);

   return prevSelectedVisit;

   });

   setSubmissionState ({submitted : true});


//   navigate("/visits/anyawyadhi", { state: { selectedPatientData } });

   console.log(selectedPatientData);


  // updatePatientDetails(selectedPatientData);


  };

  const updatePatientDetails = async (prevSelectedVisit) => {
    try {

      console.log ('prev selected visit', prevSelectedVisit);
      const patientInfo = prevSelectedVisit[0].patientInfo[0];
      const patientSymptoms = prevSelectedVisit[1].patientSymptoms[0]; 
      const patientId = patientSymptoms.PATIENT_ID;
      const caseId = patientSymptoms.CASE_ID;
      const visitId = patientSymptoms.VISIT_ID; 

      console.log('patient Info', patientInfo);
      console.log('patient symptoms', patientSymptoms);

        const response = await axios.put(`${baseURL}/visits/${patientId}/${caseId}/${visitId}`, {
            patientInfo,
            patientSymptoms
        });

        console.log('Response:', response.data);
        // Handle success (e.g., show a success message)
    } catch (error) {
        console.error('Error updating patient details:', error.response ? error.response.data : error.message);
        // Handle error (e.g., show an error message)
    }
};


  // Return student form 
  return (

    <div>
      {console.log('inside rendering: ', selectedPatientData)}
      {selectedPatientData && (
        <PatientCIForm
          initialValues={selectedPatientData}
          onSubmit={onSubmitHandler}
          enableReinitialize={true}
        >
          Update Patient
        </PatientCIForm>
      )}
    </div>
  );

};

export default EditPatientCI;

