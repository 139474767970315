import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormGroup, Button } from "react-bootstrap";

const MandalInfoForm = (props) => {

  console.log (props.initialValues);

  const mandaliInfo = props.initialValues[0];

  const initialValues = {
    MANDALI_FULL_NAME: mandaliInfo?.MANDALI_FULL_NAME || "",
    MANDALI_MOB_NO: mandaliInfo?.MANDALI_MOB_NO || "",
    MANDALI_PASSWORD: "",
    CONFIRM_PASSWORD: "", // Initial value for confirm password
    MANDALI_ROLE: mandaliInfo?.MANDALI_ROLE || ""
  };

  const validationSchema = Yup.object().shape({
    MANDALI_FULL_NAME: Yup.string().required("Full name is required"),
    MANDALI_MOB_NO: Yup.string().matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Mobile number is not valid").required("Mobile number is required"),
    MANDALI_PASSWORD: Yup.string().min(8, "Password must be at least 8 characters long").required("Password is required"),
    CONFIRM_PASSWORD: Yup.string()
        .oneOf([Yup.ref('MANDALI_PASSWORD'), null], "Passwords must match")
        .required('Confirm password is required'),
    MANDALI_ROLE: Yup.number().required("Role is required").positive().integer()
  });

  return (
    <div className="form-wrapper">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          // Exclude the confirm password from the form data
          const { CONFIRM_PASSWORD, ...formData } = values;
          console.log("Submitting Data:", formData);
          props.onSubmit(formData);
          actions.setSubmitting(false);
        }}
      >
        {({ errors, touched }) => (
        <Form>
          <FormGroup>
            <label htmlFor="MANDALI_FULL_NAME">Full Name</label>
            <Field name="MANDALI_FULL_NAME" type="text" className={`form-control ${touched.MANDALI_FULL_NAME && errors.MANDALI_FULL_NAME ? "is-invalid" : ""}`} />
            <ErrorMessage name="MANDALI_FULL_NAME" component="div" className="invalid-feedback" />
          </FormGroup>
          <FormGroup>
            <label htmlFor="MANDALI_MOB_NO">Mobile Number</label>
            <Field name="MANDALI_MOB_NO" type="text" className={`form-control ${touched.MANDALI_MOB_NO && errors.MANDALI_MOB_NO ? "is-invalid" : ""}`} />
            <ErrorMessage name="MANDALI_MOB_NO" component="div" className="invalid-feedback" />
          </FormGroup>
          <FormGroup>
            <label htmlFor="MANDALI_PASSWORD">Password</label>
            <Field name="MANDALI_PASSWORD" type="password" className={`form-control ${touched.MANDALI_PASSWORD && errors.MANDALI_PASSWORD ? "is-invalid" : ""}`} />
            <ErrorMessage name="MANDALI_PASSWORD" component="div" className="invalid-feedback" />
          </FormGroup>
          <FormGroup>
            <label htmlFor="CONFIRM_PASSWORD">Confirm Password</label>
            <Field name="CONFIRM_PASSWORD" type="password" className={`form-control ${touched.CONFIRM_PASSWORD && errors.CONFIRM_PASSWORD ? "is-invalid" : ""}`} />
            <ErrorMessage name="CONFIRM_PASSWORD" component="div" className="invalid-feedback" />
          </FormGroup>
          <FormGroup>
            <label htmlFor="MANDALI_ROLE">Role</label>
            <Field name="MANDALI_ROLE" type="number" className={`form-control ${touched.MANDALI_ROLE && errors.MANDALI_ROLE ? "is-invalid" : ""}`} />
            <ErrorMessage name="MANDALI_ROLE" component="div" className="invalid-feedback" />
          </FormGroup>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
        )}
      </Formik>
    </div>
  );
};

export default MandalInfoForm;
