import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Import Bootstrap
import { Nav, Navbar, Container, Row, Col }
  from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";


// Import from react-router-dom
import {
  BrowserRouter as Router, Routes,
  Route, Link
} from "react-router-dom";

import axios from 'axios';
import CONFIG from '../config';
import AddNewCase from './Add/AddNewCase';
import AuthUtil from './Mandali/AuthUtil';

const SelectPatientAndCases = () => {



  const [searchResults, setSearchResults] = useState([]);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientCases, setPatientCases] = useState([]);
  const [selectedCaseId, setSelectedCaseId] = useState(null);
  //const [patientVisits, setPatientVisits] = useState([]);
  const [checkState, setCheckState] = useState(null);
  const baseURL = CONFIG.HTTP_PROTCOL + CONFIG.DOMAIN_NAME;
  const {verifyTokenAndRedirect} = AuthUtil();

  let data = [];
  
  useEffect(()=>{

    verifyTokenAndRedirect();

  }, []);

  useEffect(() => {


    console.log(searchResults);
  }, [searchResults], selectedPatient, selectedCaseId, selectedPatientId, [patientCases]);


  const handleSearch = async (searchTerm) => {
    try {
      const response = await axios.get(`${baseURL}/patients/search-patients/${searchTerm}`);
      data = response.data;
      console.log(data);
      setSearchResults(data);
      // console.log(searchResults); // Log searchResults inside the .then() block
    } catch (error) {
      console.log(error);
    }
  };

  const handlePatientSelect = async (patient) => {
    setSelectedPatientId(patient.PATIENT_ID);
    setSelectedPatient(patient);

    try {
      const response = await axios.get(`${baseURL}/patients/${patient.PATIENT_ID}/cases`);
      const cases = response.data;
      //setPatientCases(cases); // Assuming the endpoint returns an array of case IDs

      console.log("patient cases :", cases);

      if (cases.length > 0) {
        setPatientCases(cases); // Set the cases in state
        setSelectedCaseId(cases[0].CASE_ID); // Automatically select the first case's ID
      } else {
        setPatientCases([]);
        setSelectedCaseId(null); // Reset selectedCaseId if there are no cases
      }


    } catch (error) {
      console.error('Error fetching patient cases:', error);
    }
  };

  return (


            <Container>
              <Row>
                <Col md={6}>
                  <h2>Search Patients</h2>
                  <PatientSearchBox onSearch={handleSearch} />
                  <h3>Search Results</h3>
                  <ul>
                    {searchResults.map(patient => (
                      <li key={patient.PATIENT_ID} onClick={() => handlePatientSelect(patient)}>
                        {patient.PATIENT_NAME}
                      </li>
                    ))}
                  </ul>
                </Col>
                <Col md={6}>
                  {selectedPatientId && (
                    <div>

                      <AddNewCase selectedPatientId = {selectedPatientId} patientCases = {patientCases} selectedPatient = {selectedPatient} />

                    <div className="cases-dropdown">
                      <h3>Patient Cases</h3>
                      <select onChange={(e) => setSelectedCaseId(e.target.value)}>
                        {patientCases.map(caseItem => (
                          <option key={caseItem.CASE_ID} value={caseItem.CASE_ID}>
                            {caseItem.CASE_ID}
                          </option>
                        ))}
                      </select>
                      <PatientCaseSelect patientId={selectedPatientId} caseId={selectedCaseId} />

                    </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          

  );
};


const PatientSearchBox = ({ onSearch }) => {


  const [searchTerm, setSearchTerm] = useState('');


  const clientSearch = () => {
    console.log(searchTerm);
    onSearch(searchTerm);
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search patients by name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button onClick={clientSearch}>Search</button>
    </div>
  );
};

const PatientCaseSelect = ({ patientId, caseId }) => {

  console.log(patientId);
  console.log(caseId);
  const navigate = useNavigate();
  const location = useLocation();

  const handleCaseSelect = (patientId, caseId) => {

    // const navigate = useNavigate();
    navigate(`/visits/${patientId}/${caseId}`, { state: location.state });

  };

  return (

    <div>
      <button onClick={() => handleCaseSelect(patientId, caseId)}>View Visits</button>
    </div>

  );

};

export default SelectPatientAndCases;
